import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '@common/auth/services/auth-guard.service';
import { ShellComponent } from '@common/shell/shell.component';
import { authRoutes } from './common/auth/auth.routing.module';
import { DashboardComponent } from './features/dashboard/pages/dashboard/dashboard.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  ...authRoutes,
  {
    path: '',
    component: ShellComponent,
    children: [
      {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'finance-report',
        loadChildren: () =>
          import('./features/finance-report/finance-report.module').then(
            (m) => m.FinanceReportModule
          )
      },
      {
        path: 'hourly-report',
        loadChildren: () =>
          import('./features/hourly-report/hourly-report.module').then(
            (m) => m.HourlyReportModule
          )
      },
      {
        path: 'adset-learning',
        loadChildren: () =>
          import('./features/facebook-adset-learning/facebook-adset-learning.module').then(
            (m) => m.FacebookAdsetLearningModule
          )
      },
      {
        path: 'page-performance',
        loadChildren: () =>
          import('./features/facebook-page-performance/facebook-page-performance.module').then(
            (m) => m.FacebookPagePerformanceModule
          )
      },
      {
        path: 'facebook-bid-adjustment',
        loadChildren: () =>
          import('./features/facebook-bid-adjustment/facebook-bid-adjustment.module').then(
            (m) => m.FacebookBidAdjustmentModule
          )
      },
      {
        path: 'ad-rules',
        loadChildren: () =>
          import('./features/ad-rule/ad-rule.module').then(
            (m) => m.AdRuleModule
          )
      },
      {
        path: 'marketing-creative',
        loadChildren: () =>
          import('./features/marketing-creative/marketing-creative.module').then(
            (m) => m.MarketingCreativeModule
          )
      },
      {
        path: 'market-dashboard',
        loadChildren: () =>
          import('./features/market-dashboard/market-dashboard.module').then(
            (m) => m.MarketDashboardModule
          )
      },
      {
        path: 'update-product-tables',
        loadChildren: () =>
          import('./features/update-product-tables/update-product-tables.module').then(
            (m) => m.UpdateProductTablesModule
          )
      },
      {
        path: 'standard-operating-procedures',
        loadChildren: () =>
          // eslint-disable-next-line max-len
          import('./features/standard-operating-procedures/standard-operating-procedures.module').then(
            (m) => m.StandardOperatingProceduresModule
          )
      },
      {
        path: 'automated-reports',
        loadChildren: () =>
          import('./features/automated-reports/automated-reports.module').then(
            (m) => m.AutomatedReportsModule
          )
      },
      {
        path: 'manage-item-master',
        loadChildren: () =>
          import('./features/manage-item-master/manage-item-master.module').then(
            (m) => m.ManageItemMasterModule
          )
      },
      {
        path: 'item-master-list',
        loadChildren: () =>
          import('./features/item-master-list/item-master-list.module').then(
            (m) => m.ItemMasterListModule
          )
      },
      {
        path: 'shopify-markets',
        loadChildren: () =>
          import('./features/shopify-markets/shopify-markets.module').then(
            (m) => m.ShopifyMarketsModule
          )
      },
      {
        path: 'picking-shorts-summary',
        loadChildren: () =>
          import('./features/picking-shorts-summary/picking-shorts-summary.module').then(
            (m) => m.PickingShortsSummaryModule
          )
      },
      {
        path: 'create-case-ids',
        loadChildren: () =>
          import('./features/create-case-ids/create-case-ids.module').then(
            (m) => m.CreateCaseIdsModule
          )
      },
      {
        path: 'search-case-ids',
        loadChildren: () =>
          import('./features/search-case-ids/search-case-ids.module').then(
            (m) => m.SearchCaseIdsModule
          )
      },
      {
        path: 'case-ids',
        loadChildren: () =>
          import('./features/case-ids/case-ids.module').then(
            (m) => m.CaseIdsModule
          )
      },
      {
        path: 'carrier-ids',
        loadChildren: () =>
          import('./features/carrier-ids/carrier-ids.module').then(
            (m) => m.CarrierIdsModule
          )
      },
      {
        path: 'vendor-ids',
        loadChildren: () =>
          import('./features/vendor-ids/vendor-ids.module').then(
            (m) => m.VendorIdsModule
          )
      },
      {
        path: 'manage-po',
        loadChildren: () =>
          import('./features/manage-po/manage-po.module').then(
            (m) => m.ManagePOModule
          )
      },
      {
        path: 'manage-po-details',
        loadChildren: () =>
          import('./features/manage-po-details/manage-po-details.module').then(
            (m) => m.ManagePODetailsModule
          )
      },
      {
        path: 'print-pdf',
        loadChildren: () =>
          import('./features/print-pdf/print-pdf.module').then(
            (m) => m.PrintPDFModule
          )
      },
      {
        path: 'reserve-locations',
        loadChildren: () =>
          import('./features/reserve-locations/reserve-locations.module').then(
            (m) => m.ReserveLocationsModule
          )
      },
      {
        path: 'replenishment-transactions',
        loadChildren: () =>
          import('./features/replenishment-transactions/replenishment-transactions.module').then(
            (m) => m.ReplenishmentTransactionsModule
          )
      },
      {
        path: 'sku-lookup',
        loadChildren: () =>
          import('./features/sku-lookup/sku-lookup.module').then(
            (m) => m.SkuLookupModule
          )
      },
      {
        path: 'facebook-ad-scaling',
        loadChildren: () =>
          import('./features/ad-scaling/ad-scaling.module').then(
            (m) => m.AdScalingModule
          )
      },
      {
        path: 'picked-units-reports',
        loadChildren: () =>
          import('./features/picked-units-report/picked-units-report.module').then(
            (m) => m.PickedUnitsReportModule
          )
      },
      {
        path: 'aged-totes',
        loadChildren: () =>
          import('./features/aged-totes/aged-totes.module').then(
            (m) => m.AgedTotesModule
          )
      },
      {
        path: 'picking-shorts-detail',
        loadChildren: () =>
          import('./features/picking-shorts-detail/picking-shorts-detail.module').then(
            (m) => m.PickingShortsDetailModule
          )
      },
      {
        path: 'unshipped-priority',
        loadChildren: () =>
          import('./features/unshipped-priority/unshipped-priority.module').then(
            (m) => m.UnshippedPriorityModule
          )
      },
      {
        path: 'packing-shorts',
        loadChildren: () =>
          import('./features/packing-shorts/packing-shorts.module').then(
            (m) => m.PackingSHortsModule
          )
      },
      {
        path: 'marketing-strategy',
        loadChildren: () =>
          import('./features/marketing-strategy/marketing-strategy.module').then(
            (m) => m.MarketingStrategyModule
          )
      },
      {
        path: 'user',
        loadChildren: () =>
          import('./features/user/user.module').then((m) => m.UserModule)
      },
      {
        path: 'boxes',
        loadChildren: () =>
          import('./features/box-packer/box-packer.module').then(
            (m) => m.BoxPackerModule
          )
      },
      {
        path: 'order',
        loadChildren: () =>
          import('./features/order/order.module').then(
            (m) => m.OrderModule
          )
      },
      {
        path: 'creatives',
        loadChildren: () =>
          import('./features/creative/creative.module').then(
            (m) => m.CreativeModule
          )
      },
      {
        path: 'giftcard-redemptions',
        loadChildren: () =>
          import('./features/gift-card-redemptions/gift-card-redemptions.module').then(
            (m) => m.GiftCardRedemptionsModule
          )
      },
      {
        path: 'sku-inventory',
        loadChildren: () =>
          import('./features/sku-inventory/sku-inventory.module').then(
            (m) => m.SkuInventoryModule
          )
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./features/report/report.module').then((m) => m.ReportModule)
      },
      {
        path: 'facebook',
        loadChildren: () =>
          import('./features/facebook/facebook.module').then(
            (m) => m.FacebookModule
          )
      },
      {
        path: 'ad-limit',
        loadChildren: () =>
          import('./features/ad-limit/ad-limit.module').then(
            (m) => m.AdLimitModule
          )
      },
      {
        path: 'facebook-ad-manager',
        loadChildren: () =>
          import('./features/ad-manager/ad-manager.module').then(
            (m) => m.AdManagerModule
          )
      },
      {
        path: 'ad-copy',
        loadChildren: () =>
          import('./features/ad-copy/ad-copy.module').then(
            (m) => m.AdCopyModule
          )
      },
      {
        path: 'batch-pack-boxes',
        loadChildren: () =>
          import('./features/batch-box-packing/batch-box-packing.module').then(
            (m) => m.BatchBoxPackingModule
          ),
        canActivate: [AuthGuardService]
      },
      {
        path: 'pii-request',
        loadChildren: () =>
          import(
            './features/pii-request/pii-request.module'
          ).then((m) => m.PiiRequestModule)
      },
      {
        path: 'shipping-threshold',
        loadChildren: () =>
          import(
            './features/shipping-threshold/shipping-threshold.module'
          ).then((m) => m.ShippingThresholdModule)
      },
      {
        path: 'shipment-review',
        loadChildren: () =>
          import(
            './features/shipment/shipment-review/shipment-review.module'
          ).then((m) => m.ShipmentReviewModule)
      },
      {
        path: 'internal-turn-time',
        loadChildren: () =>
          import(
            './features/internal-turn-time/internal-turn-time.module'
          ).then((m) => m.InternalTurnTimeModule)
      },
      {
        path: 'shipment-wave',
        loadChildren: () =>
          import(
            './features/shipment-wave/shipment-wave.module'
          ).then((m) => m.ShipmentWaveModule)
      },
      {
        path: 'wave-processing-status',
        loadChildren: () =>
          import(
            './features/wave-processing-status/wave-processing-status.module'
          ).then((m) => m.WaveProcessingStatusModule)
      },
      {
        path: 'time-usage',
        loadChildren: () =>
          import(
            './features/time-usage/time-usage.module'
          ).then((m) => m.TimeUsageModule)
      },
      {
        path: 'associate-decouple',
        loadChildren: () =>
          import(
            './features/associate-decouple/associate-decouple.module'
          ).then((m) => m.AssociateDecoupleModule)
      },
      {
        path: 'barcode-aliases',
        loadChildren: () =>
          import(
            './features/barcode-aliases/barcode-aliases.module'
          ).then((m) => m.BarcodeAliasesModule)
      },
      {
        path: 'associate-summary',
        loadChildren: () =>
          import(
            './features/associate-summary/associate-summary.module'
          ).then((m) => m.AssociateSummaryModule)
      },
      {
        path: 'associate-summary-by-supervisor',
        loadChildren: () =>
          import(
            './features/associate-summary-by-supervisor/associate-summary-by-supervisor.module'
          ).then((m) => m.AssociateSummaryBySupervisorModule)
      },
      {
        path: 'unwaved-summary',
        loadChildren: () =>
          import(
            './features/unwaved-summary/unwaved-summary.module'
          ).then((m) => m.UnwavedSummaryModule)
      },
      {
        path: 'associate-unit-details',
        loadChildren: () =>
          import(
            './features/associate-unit-details/associate-unit-details.module'
          ).then((m) => m.AssociateUnitDetailsModule)
      },
      {
        path: 'carton-lookup',
        loadChildren: () =>
          import(
            './features/carton-lookup/carton-lookup.module'
          ).then((m) => m.CartonLookupModule)
      },
      {
        path: 'manage-case-id',
        loadChildren: () =>
          import(
            './features/manage-case-id/manage-case-id.module'
          ).then((m) => m.ManageCaseIdModule)
      },
      {
        path: 'manage-lot-ids',
        loadChildren: () =>
          import(
            './features/manage-lot-ids/manage-lot-ids.module'
          ).then((m) => m.ManageLotIdsModule)
      },
      {
        path: 'replenishment-locations',
        loadChildren: () =>
          import(
            './features/replenishment-locations/replenishment-locations.module'
          ).then((m) => m.ReplenishmentLocationsModule)
      },
      {
        path: 'labor-management',
        loadChildren: () =>
          import(
            './features/labor-management/labor-management.module'
          ).then((m) => m.LaborManagementModule)
      },
      {
        path: 'attendance-points-by-date',
        loadChildren: () =>
          import(
            './features/attendance-points-by-date/attendance-points-by-date.module'
          ).then((m) => m.AttendancePointsByDateModule)
      },
      {
        path: 'timesheet',
        loadChildren: () =>
          import(
            './features/timesheet/timesheet.module'
          ).then((m) => m.TimesheetModule)
      },
      {
        path: 'file-conversions',
        loadChildren: () =>
          import(
            './features/file-conversions/file-conversions.module'
          ).then((m) => m.FileConversionsModule)
      },
      {
        path: 'box-consumption',
        loadChildren: () =>
          import(
            './features/box-consumption/box-consumption.module'
          ).then((m) => m.BoxConsumptionModule)
      },
      {
        path: 'peak-units-burn-down',
        loadChildren: () =>
          import(
            './features/peak-units-burn-down/peak-units-burn-down.module'
          ).then((m) => m.PeakUnitsBurnDownModule)
      },
      {
        path: 'wave-orders',
        loadChildren: () =>
          import(
            './features/wave-orders/wave-orders.module'
          ).then((m) => m.WaveOrdersModule)
      },
      {
        path: 'auto-wave-configuration',
        loadChildren: () =>
          import(
            './features/auto-wave-configuration/auto-wave-configuration.module'
          ).then((m) => m.AutoWaveConfigurationModule)
      },
      {
        path: 'order-status',
        loadChildren: () =>
          import(
            './features/order-status/order-status.module'
          ).then((m) => m.OrderStatusModule)
      },
      {
        path: 'fulfill-by-status',
        loadChildren: () =>
          import(
            './features/fulfill-by-status/fulfill-by-status.module'
          ).then((m) => m.FulfillByStatusModule)
      },
      {
        path: 'bridged-status',
        loadChildren: () =>
          import(
            './features/bridged-status/bridged-status.module'
          ).then((m) => m.BridgedStatusModule)
      },
      {
        path: 'bridged-units-summary',
        loadChildren: () =>
          import(
            './features/bridged-units-summary/bridged-units-summary.module'
          ).then((m) => m.BridgedUnitsSummaryModule)
      },
      {
        path: 'wms-dashboard',
        loadChildren: () =>
          import(
            './features/wms-dashboard/wms-dashboard.module'
          ).then((m) => m.WMSDashboardModule)
      },
      {
        path: 'picking-allocations',
        loadChildren: () =>
          import(
            './features/picking-allocations/picking-allocations.module'
          ).then((m) => m.PickingAllocationsModule)
      },
      {
        path: 'picked-units',
        loadChildren: () =>
          import(
            './features/picked-units/picked-units.module'
          ).then((m) => m.PickedUnitsModule)
      },
      {
        path: 'dock-doors',
        loadChildren: () =>
          import(
            './features/dock-doors/dock-doors.module'
          ).then((m) => m.DockDoorsModule)
      },
      {
        path: 'carton-audit-assignments',
        loadChildren: () =>
          import(
            './features/carton-audit-assignments/carton-audit-assignments.module'
          ).then((m) => m.CartonAuditAssignmentsModule)
      },
      {
        path: 'inventory-in-totes',
        loadChildren: () =>
          import(
            './features/inventory-in-totes/inventory-in-totes.module'
          ).then((m) => m.InventoryInTotesModule)
      },
      {
        path: 'reserve-inventory',
        loadChildren: () =>
          import(
            './features/reserve-inventory/reserve-inventory.module'
          ).then((m) => m.ReserveInventoryModule)
      },
      {
        path: 'manage-hardware-mapping',
        loadChildren: () =>
          import(
            './features/manage-hardware-mapping/manage-hardware-mapping.module'
          ).then((m) => m.ManageHardwareMappingModule)
      },
      {
        path: 'associate-outbound-hour-summary',
        loadChildren: () =>
          import(
            './features/associate-outbound-hour-summary/associate-outbound-hour-summary.module'
          ).then((m) => m.AssociateOutboundHourSummaryModule)
      },
      {
        path: 'outbound-key-metrics-by-tasks',
        loadChildren: () =>
          import(
            './features/outbound-key-metrics-by-tasks/outbound-key-metrics-by-tasks.module'
          ).then((m) => m.OutboundKeyMetricsByTasksModule)
      },
      {
        path: 'shipment-variants',
        loadChildren: () =>
          import(
            './features/shipment-variants/shipment-variants.module'
          ).then((m) => m.ShipmentVariantsModule)
      },
      {
        path: 'outbound-key-metrics',
        loadChildren: () =>
          import(
            './features/outbound-key-metrics/outbound-key-metrics.module'
          ).then((m) => m.OutboundKeyMetricsModule)
      },
      {
        path: 'manage-replenishment-tasks',
        loadChildren: () =>
          import(
            './features/manage-replenishment-tasks/manage-replenishment-tasks.module'
          ).then((m) => m.ManageReplenishmentTasksModule)
      },
      {
        path: 'high-priority-order-status',
        loadChildren: () =>
          import(
            './features/high-priority-order-status/high-priority-order-status.module'
          ).then((m) => m.HighPriorityOrderStatusModule)
      },
      {
        path: 'manage-detail-replenishment-tasks',
        loadChildren: () =>
          import(
            './features/manage-detail-replenishment-tasks/manage-detail-replenishment-tasks.module'
          ).then((m) => m.ManageDetailReplenishmentTasksModule)
      },
      {
        path: 'gift-with-purchase',
        loadChildren: () =>
          import(
            './features/gift-with-purchase/gift-with-purchase.module'
          ).then((m) => m.GiftWithPurchaseModule)
      },
      {
        path: 'non-packed-cartons',
        loadChildren: () =>
          import(
            './features/non-packed-cartons/non-packed-cartons.module'
          ).then((m) => m.NonPackedCartonsModule)
      },
      {
        path: 'fulfilled-shipments',
        loadChildren: () =>
          import(
            './features/fulfilled-shipments/fulfilled-shipments.module'
          ).then((m) => m.FulfilledShipmentsModule)
      },
      {
        path: 'associate-outbound-hourly-pivot',
        loadChildren: () =>
          import(
            './features/associate-outbound-hourly-pivot/associate-outbound-hourly-pivot.module'
          ).then((m) => m.AssociateOutboundHourlyPivotModule)
      },
      {
        path: 'associate-outbound-hourly-pivot-rnr',
        loadChildren: () =>
          import(
            // eslint-disable-next-line max-len
            './features/associate-outbound-hourly-pivot-rnr/associate-outbound-hourly-pivot-rnr.module'
          ).then((m) => m.AssociateOutboundHourlyPivotRnRModule)
      },
      {
        path: 'uph-summary',
        loadChildren: () =>
          import(
            './features/uph-summary/uph-summary.module'
          ).then((m) => m.UPHSummaryModule)
      },
      {
        path: 'task-progression',
        loadChildren: () =>
          import(
            './features/task-progression/task-progression.module'
          ).then((m) => m.TaskProgressionModule)
      },
      {
        path: 'archive-wave',
        loadChildren: () =>
          import(
            './features/archive-wave/archive-wave.module'
          ).then((m) => m.ArchiveWaveModule)
      },
      {
        path: 'task-lookup',
        loadChildren: () =>
          import(
            './features/task-lookup/task-lookup.module'
          ).then((m) => m.TaskLookupModule)
      },
      {
        path: 'task-carton-detail',
        loadChildren: () =>
          import(
            './features/task-carton-detail/task-carton-detail.module'
          ).then((m) => m.TaskCartonDetailModule)
      },
      {
        path: 'picking-locations',
        loadChildren: () =>
          import(
            './features/picking-locations/picking-locations.module'
          ).then((m) => m.PickingLocationsModule)
      },
      {
        path: 'tv-goals',
        loadChildren: () =>
          import(
            './features/tv-goals/tv-goals.module'
          ).then((m) => m.TVGoalsModule)
      },
      {
        path: 'wave-plan-tv',
        loadChildren: () =>
          import(
            './features/wave-plan-tv/wave-plan-tv.module'
          ).then((m) => m.WavePlanTVModule)
      },
      {
        path: 'fraud',
        loadChildren: () =>
          import('@features/fraud/fraud.module').then((m) => m.FraudModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'facebook-attribution',
        loadChildren: () =>
          import(
            '@features/facebook-attribution/facebook-attribution.module'
          ).then((m) => m.FacebookAttributionModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'recommendation',
        loadChildren: () =>
          import('@features/recommendation/recommendation.module').then(
            (m) => m.RecommendationModule
          ),
        canActivate: [AuthGuardService]
      },
      {
        path: 'discount-codes-report',
        loadChildren: () =>
          import(
            '@features/discount-codes-report/discount-codes-report.module'
          ).then((m) => m.DiscountCodesReportModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'discount-frequency-report',
        loadChildren: () =>
          import(
            '@features/discount-frequency/discount-frequency.module'
          ).then((m) => m.DiscountFrequencyModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'discount-tier',
        loadChildren: () =>
          import(
            '@features/discount-tier/discount-tier.module'
          ).then((m) => m.DiscountTierModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'inventory-snapshot-report',
        loadChildren: () =>
          import(
            '@features/inventory-snapshot-report/inventory-snapshot-report.module'
          ).then((m) => m.InventorySnapshotReportModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'fulfillment-metrics',
        loadChildren: () =>
          import(
            '@features/fulfillment-metrics/fulfillment-metrics.module'
          ).then((m) => m.FulfillmentMetricsModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'audience',
        loadChildren: () =>
          import('@features/audience/audience.module').then(
            (m) => m.AudienceModule
          ),
        canActivate: [AuthGuardService]
      },
      {
        path: 'facebook-query',
        loadChildren: () =>
          import('@features/facebook-query/facebook-query.module').then(
            (m) => m.FacebookQueryModule
          ),
        canActivate: [AuthGuardService]
      },
      {
        path: 'user-management',
        loadChildren: () =>
          import(
            '@features/user-management/user-management.module'
          ).then((m) => m.UserManagementModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'permissions-management',
        loadChildren: () =>
          import(
            '@features/permissions-management/permissions-management.module'
          ).then((m) => m.PermissionsManagementModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'discount-functions',
        loadChildren: () =>
          import(
            '@features/discount-functions/discount-functions.module'
          ).then((m) => m.DiscountFunctionsModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'replenishment-report',
        loadChildren: () =>
          import(
            '@features/replenishment-report/replenishment-report.module'
          ).then((m) => m.ReplenishmentReportModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'order-aging',
        loadChildren: () =>
          import(
            '@features/order-aging/order-aging.module'
          ).then((m) => m.OrderAgingModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'printed-batches',
        loadChildren: () =>
          import(
            '@features/printed-batches/printed-batches.module'
          ).then((m) => m.PrintedBatchesModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'carrier-invoice',
        loadChildren: () =>
          import(
            '@features/carrier-invoice/carrier-invoice.module'
          ).then((m) => m.CarrierInvoiceModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'barcode-functions',
        loadChildren: () =>
          import(
            '@features/barcode-functions/barcode-functions.module'
          ).then((m) => m.BarcodeFunctionsModule)
      }
    ]
  },
  { path: '**', component: PageNotFoundComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
